<template>
  <v-autocomplete
    v-model="value"
    :items="options"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    dense
    outlined
    clearable
    hide-details
    single-line
  ></v-autocomplete>
</template>

<script>
import {httpClient} from "@/libs/http";
import {debounce} from "lodash/function";

export default {
  name: 'SelectEMarket',
  props: {
    name: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      default: () => 'Sàn'
    },
    placeholder: {
      type: String,
      default: () => 'Sàn'
    },
    filters: {
      type: Object,
      default: () => {}
    },
    isFilter: {
      type: Boolean,
      default: () => false
    },
  },
  data: () => ({
    value: null,
    options: []
  }),
  computed: {

  },
  watch: {
    value() {
      this.onFilter()
    },
  },
  methods: {
    onFilter: debounce(function () {
      this.$emit('onFilter', {
        name: this.name,
        value: this.value
      })
    }, 100),
    async getList() {
      const {data} = await httpClient.post('/list-e-market')
      const options = data.map(e => ({
        value: e.id,
        text: e.full_name || ''
      }))
      if (this.isFilter) {
        options.push({
          value: 'none',
          text: '# Sàn'
        },{
          value: 'transfer',
          text: 'Chuyển nội bộ'
        })
      }
      this.options = [...options]
    },
  },
  mounted() {
    this.getList()
  }
}
</script>
